import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { env } from 'src/env';
import { HttpService } from '../helpers/http.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private router: Router,
    private _http: HttpService,
    private http: HttpClient,
    )
    { }

   getUtilities() {
    return this.http.get(`${env.API_URL}/utilities`, {
      headers: this._http.header,
    });
  }

  /**
   *
   * @param user
   * @returns http response
   */
  userLogin(user: any) {
    return this.http.post(`${env.API_URL}/backend/auth/login`, user);
  }

  /**
   * Auth Logout
   */
  logout() {
    return this.http.post(`${env.API_URL}/backend/auth/logout`, [], {
      headers: this._http.header,
    });

  }

}
