// export const environment = {
//   APP_NAME: 'Qayim Dactory',
//   APP_URL: 'http://localhost:4201',
//   API_URL: 'http://api.qdactory.com/api',
//   API_GROUP_BACKEND: 'backend',
//   ASSET_URL: './assets/',
//   APP_LANG: 'en',
// };


export const environment = {
  APP_NAME: 'Qayim Dactory',
  APP_URL: 'https://admin.qayimdactory.com/',
  API_URL: 'https://api.qayimdactory.com/api',
  API_GROUP_BACKEND: 'backend',
  ASSET_URL: './assets/',
  APP_LANG: 'ar',
};
