import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { LanguageService } from 'src/app/core/helpers/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentLang: any;
  constructor(public _lang: LanguageService) {
    this.currentLang = this._lang.currentLanguage();
  }

  ngOnInit(): void {
    this._lang.changeLanguage(this.currentLang);
    $('a.open_close').on('click', function () {
      $('.main-menu').toggleClass('show'),
        $('.layer').toggleClass('layer-is-visible');
    }),
      $('a.show-submenu').on('click', function () {
        $(this).next().toggleClass('show_normal');
      }),
      $('a.show-submenu-mega').on('click', function () {
        $(this).next().toggleClass('show_mega');
      }),
      $(window).on('resize load', function () {
        $(this).width() < 480 &&
          $('a.open_close').on('click', function () {
            $('.cmn-toggle-switch').removeClass('active');
          });
      });

    // var width = $(window).width();
    // if (width <= 768) {
    //   if ($('#mobileview .mobile-lang').is(':empty')) {
    //     $('#topLang').clone().appendTo($('#mobileview .mobile-lang'));
    //   }
    //   if ($('#mobileqsearch').is(':empty')) {
    //     $('#HeadQSearch').clone().appendTo($('#mobileqsearch'));
    //   }
    //   if ($('#mobile-rate').is(':empty')) {
    //     $('#DrsSlider').clone().appendTo($('#mobile-rate'));
    //   }

    //   $('#rated').hide();
    //   $('#home-tabs a').on('click', function () {
    //     setTimeout(function () {
    //       if ($('.js-tabs-content').hasClass('active')) {
    //         $('.slick-prev').click();
    //       }
    //     }, 100);
    //   });
    // } else {
    //   $('#mobileview').hide();
    // }
  }

  /**
   *
   * @param lang
   * change language
   */
  changeLang(lang: any) {
    this._lang.changeLanguage(lang);
    this.currentLang = lang;
  }
}
