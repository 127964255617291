import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  /**
   * show loader globly
   */
  globleLoader = new BehaviorSubject(false);

  /**
   * show table progress bar
   */
  showTableLoader = new BehaviorSubject(false);

  /**
   * defualt image for display
   */
  defualtimage = './assets/images/men_profile.jpg';
  defualt_image_dr_male = './assets/images/dr_male_profile.jpg';
  defualt_image_dr_female = './assets/images/dr_female_profile.jpg';
  defualt_image_gallery = './assets/images/flag.jpg';
  default_banner_img = './assets/images/banner.jpg';
  /**
   * get defualt image
   */
  get defualtimg() {
    return this.defualtimage;
  }

  /**
   * get defualt image Gallery
   */
  defaultImage(type: any) {
    if (type === 'gallery') {
      return this.defualt_image_gallery;
    } else if (type === 'dr_male') {
      return this.defualt_image_dr_male;
    } else if (type === 'dr_female') {
      return this.defualt_image_dr_female;
    } else if (type === 'profile') {
      return this.defualtimage;
    } else if (type === 'banner') {
      return this.default_banner_img;
    }
  }

  /**
   * go to privios page
   */
  back() {
    window.history.back();
  }
}
