import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/helpers/auth.service';

const ADMIN_MENU = [
  {
    displayName: 'Dashboard',
    iconName: 'icon_hourglass',
    route: '/admin/dashboard',
    isMainMenu: true,
    menu: 'Admin Menu',
    role: ['master-admin', 'admin'],
  },
  {
    displayName: 'Doctors',
    iconName: 'fa fa-user-md',
    route: 'admin/doctor/list',
    role: ['master-admin', 'admin'],
  },
  {
    displayName: 'Claimed Doctors',
    iconName: 'fa fa-user-md',
    route: 'admin/claimed/doctor/list',
    role: ['master-admin', 'admin'],
  },
  {
    displayName: 'Institutes',
    iconName: 'fa fa-hospital-o',
    route: 'admin/institute/list',
    role: ['master-admin', 'admin'],
  },
  {
    displayName: 'Claimed Institutes',
    iconName: 'fa fa-hospital-o',
    route: 'admin/claimed/institute/list',
    role: ['master-admin', 'admin'],
  },
  {
    displayName: 'Patients',
    iconName: 'fa fa-user-plus',
    route: 'admin/patient/list',
    role: ['master-admin'],
  },
  // {
  //   displayName: 'Institute',
  //   iconName: 'icon_hourglass',
  //   route: 'admin/institute/list',
  //   role: ['master-admin', 'admin'],
  // },
  {
    displayName: 'Sub Admins',
    iconName: 'fa fa-users',
    route: 'admin/user/list',
    isManageMenu: true,
    role: ['master-admin'],
  },
  {
    displayName: 'Applications',
    iconName: 'fa fa-rocket',
    route: '/admin/application/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Appointments',
    iconName: 'fa fa-briefcase',
    route: '/admin/appointment/list',
    role: ['master-admin'],
  },
  {
    displayName: 'CMS',
    iconName: 'fa fa-file-text',
    route: '/admin/cms/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Departments',
    iconName: 'fa fa-building',
    route: '/admin/department/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Specialties',
    iconName: 'fa fa-graduation-cap',
    route: '/admin/specialty/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Sub Specialties',
    iconName: 'fa fa-briefcase',
    route: '/admin/sub-speciality/list',
    role: ['master-admin'],
  },
  {
    displayName: 'FAQ',
    iconName: 'fa fa-question',
    route: '/admin/faq/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Inquiries',
    iconName: 'fa fa-home',
    route: '/admin/inquiry/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Institute Type',
    iconName: 'fa fa-home',
    route: '/admin/institutetype/list',
    role: ['master-admin'],
  },
  {
    displayName: 'KPI Type',
    iconName: 'fa fa-file',
    route: '/admin/kpi/list',
    role: ['master-admin'],
  },
  {
    displayName: 'KYC Type',
    iconName: 'fa fa-book',
    route: '/admin/kyc/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Ranks',
    iconName: 'fa fa-sort-numeric-asc',
    route: '/admin/rank/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Packages',
    iconName: 'fa fa-file',
    route: '/admin/package/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Payments',
    iconName: 'fa fa-dollar',
    route: '/admin/payment/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Report Reasons',
    iconName: 'fa fa-flag-o',
    route: '/admin/report-reason/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Ratings',
    iconName: 'fa fa-star',
    route: '/admin/rating/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Flagged Comments',
    iconName: 'fa fa-flag',
    route: '/admin/flags/list',
    role: ['master-admin'],
  },

  {
    displayName: 'Countries',
    iconName: 'fa fa-globe',
    route: '/admin/country/list',
    menu: 'Manage',
    role: ['master-admin'],
  },
  {
    displayName: 'States',
    iconName: 'fa fa-flag',
    route: '/admin/state/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Cities',
    iconName: 'fa fa-flag-checkered',
    route: '/admin/city/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Notifications',
    iconName: 'fa fa-bell',
    route: '/admin/notification/list',
    role: ['master-admin'],
  },

  {
    displayName: 'Activities',
    iconName: 'fa fa-bookmark-o',
    route: '/admin/activity/list',
    role: ['master-admin'],
  },
  {
    displayName: 'Settings',
    iconName: 'fa fa-cogs',
    route: '/admin/settings/setting',
    role: ['master-admin'],
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  expanded: boolean = false;
  SidebarMenu: any;
  currentRole: any;
  constructor(public router: Router, private _auth: AuthService) {}

  ngOnInit(): void {
    this.SidebarMenu = ADMIN_MENU;
    this.currentRoles();
  }

  /**
   *
   * @param item
   * on select sidebar item check for children
   */
  onItemSelected(item: any) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      //this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  /**
   * fetch current role
   */
  currentRoles() {
    let user = this._auth.getLocalDataByKey('user');
    this.currentRole = user.user_role;
  }

  /**
   *
   * @param currentrole
   * @param role
   * check role match or not
   */
  checkRole(currentrole: any, roles: any) {
    return roles.includes(currentrole);
  }
}
