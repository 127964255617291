import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { env } from 'src/env';
import { AuthService } from './auth.service';
// import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    // private _sidebar: SidebarService
    private auth: AuthService
  ) {
    this.currentLang = this.currentLanguage();
  }
  currentLang: any;
  /**
   *
   * @param lang
   * @change current language and direction rtl to ltr etc.
   */
  changeLanguage(lang: any) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = lang === 'ar' ? 'rtl' : 'ltr';
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.changeCssFile(lang);
  }

  /**
   *
   * @param lang
   * @on change language change css file for rtl and ltr
   */
  changeCssFile(lang: string) {
    lang != this.currentLanguage() ? location.reload() : '';
    this.setLanguage(lang);
    let headTagss = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;

    let existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;

    let existingLinks = this.document.getElementsByClassName('QDLANGFILES');
    let ltrBundle = [
      '/assets/css/bootstrap-ltr.css',
      '/assets/css/reset.css',
      '/assets/css/menu-ltr.css',
      '/assets/css/responsive-ltr.css',
      '/assets/css/icons.css',
      '/assets/css/select2.css',
      '/assets/css/style-ltr.css',
      '/assets/css/theme-ltr.css',
    ];
    let rtlBundle = [
      '/assets/css/bootstrap-rtl.css',
      '/assets/css/reset.css',
      '/assets/css/menu-rtl.css',
      '/assets/css/responsive-rtl.css',
      '/assets/css/icons.css',
      '/assets/css/select2.css',
      '/assets/css/style-rtl.css',
      '/assets/css/theme-rtl.css',
    ];

    let currentBundle = lang === 'ar' ? rtlBundle : ltrBundle;
    if (existingLinks.length > 0) {
      for (let i = 0; i < existingLinks.length; i++) {
        let link = existingLinks[i] as HTMLLinkElement;
        link.href = currentBundle[i];
      }
    } else {
      let headTags = this.document.getElementsByTagName('head');
      for (let i = 0; i < currentBundle.length; i++) {
        let newLink = this.document.createElement('link');
        let head = headTags[0] as HTMLHeadElement;
        newLink.rel = 'stylesheet';
        newLink.type = 'text/css';
        newLink.className = 'QDLANGFILES';
        newLink.href = currentBundle[i];
        head.appendChild(newLink);
      }
    }

    // let bundleName =
    //   lang === 'ar'
    //     ? '/assets/styles/styles-ar.scss'
    //     : '/assets/styles/styles-en.scss';
    // if (existingLink) {
    //   existingLink.href = bundleName;
    // } else {
    //   let newLink = this.document.createElement('link');
    //   newLink.rel = 'stylesheet';
    //   newLink.type = 'text/css';
    //   newLink.id = 'langCss';
    //   newLink.href = bundleName;
    //   headTag.appendChild(newLink);
    // }
  }

  /**
   *
   * @returns current app language
   */
  currentLanguage() {
    return this.auth.getLocalDataByKey('lang')
      ? this.auth.getLocalDataByKey('lang')
      : env.APP_LANG;
  }

  /**
   *
   * @param lang
   * set app language
   */
  setLanguage(lang: any) {
    this.auth.setLocalData(lang, 'lang');
  }

  getTranslationKey() {
    this.translateService.getTranslation('en').subscribe((res) => {
      console.log(res);
    });
  }
}
