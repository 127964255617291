import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { env } from 'src/env';
import { LocalService } from './local.service';
import { AuthService as Auth } from './../../pages/auth/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private localService: LocalService,
    private router: Router,
    private injector: Injector
  ) {}

  /**
   *
   * @returns dashboard utilities
   */
  setLocalData(data, key) {
    if (typeof window !== 'undefined') {
      let newObj = this.localService.getJsonValue(env.APP_DATA_KEY);
      if (newObj === null) {
        newObj = {};
        this.localService.setJsonValue(env.APP_DATA_KEY, {});
      }
      // if (newObj.hasOwnProperty(key)) {
      //   newObj[key] = data;
      // } else {
      //   newObj[key] = data;
      // }
      newObj[key] = data;
      this.localService.setJsonValue(env.APP_DATA_KEY, newObj);
    }
  }

  setAllData(key, data) {
    if (typeof window !== 'undefined') {
      this.localService.setJsonValue(key, data);
    }
  }

  getLocalData() {
    return this.localService.getJsonValue(env.APP_DATA_KEY);
  }

  getLocalDataByKey(key: any) {
    let newObj = this.localService.getJsonValue(env.APP_DATA_KEY);
    if (newObj === null) {
      newObj = {};
      this.localService.setJsonValue(env.APP_DATA_KEY, {});
      return null;
    }
    if (typeof newObj[key] !== 'undefined') {
      return this.localService.getJsonValue(env.APP_DATA_KEY)[key];
    }
    return null;
  }

  /**
   * set utilities
   */
  setUtilites() {
    return new Promise((resolve) => {
      const auth = this.injector.get(Auth);
      auth.getUtility().subscribe((res: any) => {
        {
          this.setLocalData(res?.data, 'utility');
          resolve(200);
        }
      });
    });
  }

  /**
   * set utilites data if not exist
   */
  setUtilitiesIfNotExist() {
    return new Promise((resolve) => {
      let utilities = this.getLocalData().utility;
      if (typeof utilities == 'undefined' || !utilities) {
        this.setUtilites().then((val) => {
          resolve(200);
        });
      } else if (
        typeof utilities._countries == 'undefined' ||
        !utilities._countries
      ) {
        this.setUtilites().then((val) => {
          resolve(200);
        });
      } else {
        resolve(200);
      }
    });
  }
  /**
   *
   * @returns boolean value base on user login or not
   */
  isLoggedIn() {
    let token = this.getLocalDataByKey('token');
    let user = this.getLocalDataByKey('user');
    return (
      token !== null &&
      typeof user.user_role !== 'undefined' &&
      (user.user_role == 'master-admin' || user.user_role == 'admin')
    );
  }

  /**
   * logout user
   */
  logout() {
    this.localService.clearToken();
    this.router.navigate(['/auth/login']);
  }
}
