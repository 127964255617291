import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/helpers/auth.service';
import { ApiService } from 'src/app/core/model/api.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(private auth: AuthService, private api:ApiService) {} //

  ngOnInit(): void {

    let obj = { key: 'value' };
    this.auth.setLocalData(obj, 'user');
  }

  userLogin(){
    let obj = {};
    obj["email"] = "master@qayimdactory.com";
    obj["password"] = "password";
    this.api.userLogin(obj).subscribe((response)=>{
      console.log("response",response);
    })
  }
}
